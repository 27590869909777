import React, { useContext } from 'react'
import './style.css'
import { TableContainer } from '../table-container/table-container'
import { TimerModel } from '../../../@types/timer'
import { Table } from 'antd'
import { formatDate, formatNumber, TimePickerAction } from 'rk-react-component'
import moment from 'moment'
import { formatSecondToTimer } from '../../../services/format'
import { api } from '../../../services/api'
import { MeContext } from '../../../contexts/me-context/me-context'

interface TableTimerProps {
  dataSource: TimerModel[]
  loading: boolean
  loadData: () => void
}

export const TableTimer = ({ dataSource, loading, loadData }: TableTimerProps) => {
  const { role } = useContext(MeContext)
  return (
    <TableContainer
      pagination={false}
      id={'table_timer'}
      loading={loading}
      columns={[
        {
          align: 'center',
          dataIndex: 'start_at',
          key: 'start_at',
          title: 'Início',
          render: (val, record) =>
            role?.role === 'MASTER' ? (
              <TimePickerAction
                style={{}}
                extraData={{ end_at: record?.end_at }}
                id={record?.id}
                api={api}
                url={'/timers-dates'}
                value={moment(val)}
                showLabel={true}
                format={'HH:mm:ss'}
                onFinish={() => loadData()}
                fieldName={'start_at'}
                datePickerProps={{
                  variant: 'outlined',
                }}
              />
            ) : (
              formatDate(val, 'HH:mm:ss')
            ),
        },
        {
          align: 'center',
          dataIndex: 'end_at',
          key: 'end_at',
          title: 'Término',
          render: (val, record) =>
            role?.role === 'MASTER' ? (
              <TimePickerAction
                style={{}}
                extraData={{ start_at: record?.start_at }}
                id={record?.id}
                api={api}
                url={'/timers-dates'}
                value={moment(val)}
                showLabel={true}
                format={'HH:mm:ss'}
                onFinish={() => loadData()}
                fieldName={'end_at'}
                datePickerProps={{
                  variant: 'outlined',
                }}
              />
            ) : (
              formatDate(val, 'HH:mm:ss')
            ),
        },
        {
          align: 'center',
          dataIndex: 'total',
          key: 'total',
          title: 'Tempo Total',
          render: (val, record) => {
            if (record?.end_at && record?.start_at) {
              const duration = moment(record?.end_at).diff(moment(record?.start_at), 'second')
              return formatSecondToTimer(duration)
            }
            return ''
          },
        },
        {
          align: 'center',
          dataIndex: 'total_distance',
          key: 'total_distance',
          title: 'Distância Total',
          render: (val, record) => {
            return formatNumber(val, { decimal: 3, locale: 'pt-BR' }) + ' km'
          },
        },
        {
          align: 'center',
          dataIndex: 'speed_avg',
          key: 'speed_avg',
          title: 'Média de Velocidade',
          render: (val, record) => {
            const avg = record?.total_distance / (record?.total_timer / 3600)
            return formatNumber(avg, { decimal: 0, locale: 'pt-BR' }) + ' km/h'
          },
        },
      ]}
      dataSource={dataSource}
      summary={() => {
        const total = dataSource?.length
          ? dataSource.reduce((acc, cur) => {
              if (cur?.end_at && cur?.start_at) {
                const duration = moment(cur?.end_at).diff(moment(cur?.start_at), 'second')
                return acc + duration
              }
              return acc
            }, 0)
          : 0
        const total_distance = dataSource?.length
          ? dataSource.reduce((acc, cur) => {
              return acc + (cur?.total_distance || 0)
            }, 0)
          : 0

        const total_avg = dataSource?.length
          ? dataSource.reduce((acc, cur) => {
              const avg = cur?.total_distance / (cur?.total_timer / 3600)
              return acc + avg
            }, 0)
          : 0
        return (
          <Table.Summary.Row style={{ backgroundColor: 'lightgray' }}>
            <Table.Summary.Cell index={0} colSpan={2} align={'right'}>
              <strong>Total</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} colSpan={1} align={'center'}>
              <strong>{formatSecondToTimer(total)}</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} colSpan={1} align={'center'}>
              <strong>{formatNumber(total_distance, { decimal: 3, locale: 'pt-BR' }) + ' km'}</strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} colSpan={1} align={'center'}>
              <strong>{formatNumber(total_avg / dataSource?.length, { decimal: 0, locale: 'pt-BR' }) + ' km/h'}</strong>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )
      }}
    />
  )
}
